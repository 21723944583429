.loading {
  &::after {
    content: "";
    display: inline-block;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.05);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: #fff;

    animation: spin 1s infinite;
  }
}

.load-success {
  &::after {
    content: "";
    display: inline-block;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.05);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: #fff;

    animation: spin 1s infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#sagrada-tickets-table {
  border: 1px solid gray;

  thead tr td {
    background-color: lightgray;
    padding: 0.3rem 1rem;
    border-bottom: 1px solid gray;
  }

  tbody tr td {
    padding: 0.3rem 1rem;
  }
}

.sagrada-input-form {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.row-cards-counters {
  display: flex;
  justify-content: left;

  .summary-callout {
    max-width: 150px;
    margin: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;
    padding: 0.2rem 1rem;
  }
}

.toInactive {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: #f9b115;
  border: 0;
  margin: 0px 4px;
  // border: 3px solid;
  // border-color: darken(#f9b115, 10%);
}

.toDeleted {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: #e55353;
  border: 0;
  margin: 0px 4px;
  // border: 3px solid;
  // border-color: darken(#f9b115, 10%);
}

.sagrada-refund-form {
  padding: 10px 15px;
  display: flex;
  justify-content: start;
  align-items: center;

  select {
    margin-left: 10px;
  }
}

.sagrada-refund-amount {
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: baseline;

  label {
    margin: 0;
  }
}

.otherReason {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 2px solid #fff;
  outline: none;
  width: 75%;

  &::placeholder {
    color: #ddd;
  }
}

.selectReason > option {
  color: #333;
}