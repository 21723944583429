
.logo {
  width: 184px;
  height: 42px;
  margin: 20px 0;
  background: url(https://it-cubation.com/wp-content/uploads/2019/10/Logo-Alternate-Layout-White-184x42.png) no-repeat;
}

.logo-small {
  width: 184px;
  height: 42px;
  margin: 20px 0;
  background: url() no-repeat;
}