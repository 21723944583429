body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.conversion-table thead tr th, .conversion-table tbody tr td {
  padding: 10px;
}

.conversion-table thead tr {
  background: lightgray;
}

.ar_table {
  width: 100%;
}

.ar_table thead tr th, .ar_table tbody tr td {
  padding: 10px;
}

.ar_table thead tr th {
  background: lightgray;
}

.ar_table tbody tr:nth-child(even) {
  background: #eee;
}

.ar_table th {
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
