/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/* Firefox */
input[type=number] {
    -moz-appearance: textfield; }

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value; }

#subordinateForm {
    padding: 0px 10px;

    .orderType {
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
        border-left: 2px solid #fff;
        padding-left: 5px; }

    .hidden {
        display: none; }

    .ben-row {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            width: 22px;
            height: 22px;
            position: relative;
            appearance: none;
            -webkit-appearance: none;
            border-radius: 0.25em;
            box-sizing: border-box;
            padding: 0;
            overflow: visible; }

        input {
            margin-right: 10px;
            font-size: 15px;
            color: #333;
            margin-bottom: 5px;

            &::before {
                content: '\2713';
                color: white;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                font-size: 0.75em;
                line-height: 1.95em;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                background: #2196F3;
                z-index: 2;
                border-radius: 0.3em;
                transition: opacity .2s;
                opacity: 0; }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                border: 1px solid #2196F3;
                border-radius: 0.25em;
                z-index: 1; }

            &:checked, &:hover:checked {
                border-color: #0170B9;
                background-color: #0170B9;
                box-shadow: none; }

            &:checked::before {
                opacity: 1; } } }


    .input-modal-wrapper {
        width: 40%;
        margin-bottom: 10px;

        label {
            font-size: 14px;
            font-weight: bold; }

        .input-modal {
            width: 100%;
            border: 0;
            padding: 5px;
            outline: none;
            background: transparent;
            color: #fff;
            border-bottom: 1px solid #fff;

            &::-webkit-calendar-picker-indicator {
                filter: invert(1); }

            // &::-webkit-input-placeholder
            //     color: #fff

            // &::-moz-placeholder
            //     color: #fff

            // &:-ms-input-placeholder
            //     /** notice that ie has only a single colon) */
            //     color: #fff

            // &::-webkit-input-placeholder
            //     color: #fff

            // &::placeholder
            //     color: #fff

            &:focus {
                @include box-shadow(0px 10px 15px -9px white); } } } }
