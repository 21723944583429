.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 10%;
  }
  
  .rowHeader {
      display: flex;
      align-items: center;
      margin: 0 25%;
      border-bottom: 1px solid #ccc; 
      justify-content: center; 
      padding-bottom: 5px;
      margin-bottom: 10px;
    }