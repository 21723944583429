// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.cards-confirm {
  // border: 1px solid grey;
  margin: 0 0 10px;

  button {
    background-color: #2eb85c;
    color: #eee;
    letter-spacing: 2px;
    font-weight: bold;
    outline: none;
    box-shadow: none;
    border: 1px solid darken(#2eb85c, 30%);
    border-radius: 5px;
  }
}

.custom-file-upload {
  background: #2eb85c;
  color: #eee;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  border: 1px solid darken(#2eb85c, 30%);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 1rem;
}

.actionButton.MuiSvgIcon-root:hover {
  fill: red;
  transform: translateY(-2px);
}

.actionButton.MuiSvgIcon-root:active {
  fill: red;
  transform: translateY(0px);
}

.actionButton {
  cursor: pointer;
}
